<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import Multiselect from "vue-multiselect";
import Drawer from "vue-simple-drawer";
import FilterP from "@/components/personalizations/filter";

import {
	contentMethods,
  projectMethods

} from "@/state/helpers";

export default {
  page: {
    title: "Personalizations",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    Multiselect,
    Drawer,
    FilterP
  },
  data() {
    return {
      title: "Personalizations",
      items: [],
			currentPage:1,
			perPage:8,
      rows:0,
			isLoading:false,
      personalizations:[],
      confirmDelete:false,
      personalization: null,
      showPreviewPanel:false,
      url:null,
      query:'',
      empty_list_config:{
            "title" : this.$t("personalizations.empty_search_title"),
            "linkButtonText" : this.$t("common.reset_search"),
            icon: "fa-cubes",
        },
        empty_config:{
            "title" : this.$t("personalizations.empty_title"),
            "subtitle" : this.$t("personalizations.empty_text"),
            "buttonText" : this.$t("personalizations.new_personalization"),
            icon: "fa-cubes",
            academy: "https://academy.gopersonal.ai/guia-de-usuario/personalizaciones"
        },
        showNewPersonalizarionModal: false,
        showCopyToProjectModal: false,
        projects:[],
        auxProject:null,
        isLocal: window.config.env == 'dev',
        openRightDrawer: false,
        filters: [],
    };
  },
  created(){
		this.debouncePersonalizations = _debounce(this.searchPersonalizations.bind(this), 1000);
	},
	mounted(){
		const project = localStorage.getItem("current_project");
    this.url = localStorage.getItem(`site_url_${project}`);

    this.filters = localStorage.getItem("personalizations_filter") ? JSON.parse(localStorage.getItem("personalizations_filter")) : [];

    this.loadPersonalizations();
	},
  methods:{
    ...contentMethods,
    ...projectMethods,

    onPreviewPersonalization(data){
      this.showPreviewPanel = true;
      this.personalization = data;
    },

    onConfirmPreViewClicked(){
      
      const project = localStorage.getItem("current_project");
      localStorage.setItem(`site_url_${project}`,this.url);

      this.showPreviewPanel = false;
      const URL = `${this.url}?gsIncludeDraft=true`; 
      window.open(URL, '_blank'); 
    },
		onRemovePersonalization(personalization){
      this.personalization = personalization;
      this.confirmDelete = true;
    },
    onConfirmRemovePersonalizationClicked(){
      let loader = this.$loading.show();

      this.deleteContent(this.personalization._id).then(()=>{
        this.currentPage=1;
        this.loadPersonalizations();
        this.$notify({ type: 'success', text: this.$t('personalizations.delete_personalization_success'),title:  this.$t('personalizations.title') });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('personalizations.delete_personalization_error'),title:  this.$t('personalizations.title') });
      }).finally(()=>{
        loader.hide();
      })
    },

    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          if(f.key == 'goal'){
            q+= `, "goal._id": "${f.value}"`
          }else if(f.key=='channelType' && f.value == 'any'){
            q+= '';
          }
          else{
            q+= `, "${f.key}": "${f.value}"`
          }
          
        });
      }
      return q;
    },

		loadPersonalizations(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      let filter = ''
      if(this.filters.length > 0){
        filter = this.getCustomFilterQuery();
      }
      
      const params = {
				q : `whereObj={"project":"${localStorage.getItem('current_project')}"${filter}}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
			}

			this.getContents(params).then((contents)=>{
        if(contents&& contents.data){
          this.personalizations= contents.data;
          this.rows= contents.totalCount
				}
      }).catch(()=>{
        this.$notify({ type: 'error', text:this.$t('personalizations.get_personalizations_error'),title:  this.$t('personalizations.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    loadProjects(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
			const params = {
				q : `limit=50`
			}

			this.getProjects(params).then((projects)=>{
        if(projects&& projects.data){
          this.projects= projects.data;
          this.projects = this.projects.filter(p=> p._id != localStorage.getItem('current_project'));
        }
      }).catch(()=>{
        
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    searchPersonalizations(query){
      
			let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("personalizations.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.personalizations=[];
        this.currentPage = 1;
        let filter='';

        if(this.filters.length > 0){
        filter = this.getCustomFilterQuery();
      }
        const params = {
          q : `whereObj={"name": {"$regex" : ".*${query}*." , "$options": "i"}, "project":"${localStorage.getItem('current_project')}"${filter}}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
        }

        this.getContents(params).then((contents)=>{
          if(contents&& contents.data){
            this.personalizations= contents.data;
            this.rows= contents.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadPersonalizations();
      }
    },
		onPersonalizationListPageClicked(){
			this.loadPersonalizations();
		},	
    onEditPersonalizationClicked(data){
      this.$router.push({
            path: `/personalization?id=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onCreatePersonalizationClicked(channel){

      this.$router.push({
            path: `/personalization?channel=${channel}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onPublishOrUnpublishPersonalization(data){
      let loader = this.$loading.show();
      const personalization = {
        _id: data._id,
        status : !data.status
      }
      this.updateContent(personalization).then(()=>{
          this.$notify({ type: 'success', text: this.$t('personalizations.update_personalization_success'),title:  this.$t('personalizations.title') });
          this.loadPersonalizations();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('personalizations.update_personalization_error'),title:  this.$t('personalizations.title') });
        }).finally(()=>{
          loader.hide();
        })
    },
    onResetSearchClicked(){
      this.query = '';
      this.loadPersonalizations();
    },
    onCopyPersonalization(data){
      this.personalization = data;
      this.loadProjects();
      this.showCopyToProjectModal = true;
    },
    onConfirmCopyPersonalization(){
      if(this.auxProject){
        delete this.personalization._id;
        delete this.personalization.createdAt;
        delete this.personalization.createdBy;
        delete this.personalization.updatedAt;
        delete this.personalization.updatedBy;

        this.personalization.status = 0;
        this.personalization.project = this.auxProject._id;
        this.personalization.targets.forEach((t)=>{
          delete t._id;
          t.status = 0;
          t.variants.forEach((v)=>{
            delete v._id;
            v.status = 0;
          })
        })

        let loader = this.$loading.show();

        this.createContent(this.personalization).then(()=>{
          this.$notify({ type: 'success', text: this.$t('personalizations.copy_to_project_personalization_success'),title:  this.$t('personalizations.title') });
          this.auxProject = null;
          this.personalization = null;
          this.showCopyToProjectModal = false;
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('personalizations.copy_to_project_personalization_error'),title:  this.$t('personalizations.title') });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        this.showCopyToProjectModal = false;
      }
    },

    onAnalyticsClicked(data){
      if(data.type == 'feedback'){
        this.$router.push({
            path: `/feedback-analyze?personalization=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
      }else{
        this.$router.push({
            path: `/personal-analyze?personalization=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
      }
      
    },
    rightDrawer(){
      this.openRightDrawer = false;
    },
    removeAllFilter(){
      this.$refs.filterRef.removeAll();

      this.applyCustomFilter();
    },
    applyCustomFilter(){
      this.openRightDrawer = false;

      this.filters = this.$refs.filterRef.getAllFilters();
      localStorage.setItem('personalizations_filter',JSON.stringify(this.filters));
      this.currentPage = 1;
      this.searchPersonalizations(this.query);
    },
    onFilterClicked(){
      this.openRightDrawer=true; 
      setTimeout(()=>{
        this.$refs.filterRef.setAllFilter(this.filters)
      },1000)
    },
    onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);
      localStorage.setItem('personalizations_filter',JSON.stringify(this.filters));
      this.currentPage = 1;
      this.searchPersonalizations(this.query);
    },
    getTemplateType(type){
      switch(type){
        case 'web': return this.$t("personalizations.type_web"); 
        case 'recommendations': return this.$t("personalizations.type_reco"); 
        case 'custom_code': return this.$t("personalizations.type_custom_code"); 
        case 'pop_up': return this.$t("personalizations.type_pop_up"); 
        case 'notifications': return this.$t("personalizations.type_notifications");
        case 'feedback' : 'Feedback'
      }
      return type;
    },
    onAnalyzeClicked(){
      this.$router.push({
            path: `/personal-analyze`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('personalizations.title')" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body" :class="filters.length > 0 ? 'pb-2' : ''">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debouncePersonalizations($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
                <ul class="nav nav-pills product-view-nav" v-on:click="onFilterClicked" v-if="false">
                  <li class="nav-item">
                    <a class="nav-link active" href="javascript: void(0);">
                      <i class="bx bx-filter align-middle"></i>
                    </a>
                  </li>
                </ul>
            </div>
            <div class="col text-sm-end">
              <button
                      type="button"
                      class="btn btn-primary mb-0 me-2"
                      @click="showNewPersonalizarionModal = true"
                      v-if="personalizations.length>0 || query!=''">
                  <i class="mdi mdi-plus me-1"></i> {{$t('personalizations.new_personalization')}}
              </button>

              <b-dropdown ref="actionsDropdown" ngbDropdown menu-class="dropdown-menu-sm dropdown-menu-end pb-0" right variant="outline-primary" v-if="personalizations.length>0 || query!=''">
                    <template v-slot:button-content class="ms-2">
                      <i class="mdi mdi-apps me-1 text-primary"></i>
                      <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                        {{$t('common.actions')}}
                        <i class="mdi mdi-chevron-down text-primary"></i>
                      </span>
                    </template>
                    
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onFilterClicked">
                      <i class="mdi mdi-filter-variant"></i>
                      {{ $t('common.filter') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onAnalyzeClicked">
                      <i class="mdi mdi-chart-line"></i>
                      {{ $t('menu.search_analyze') }}
                    </a>
										<div class="dropdown-divider mb-0"></div>
              </b-dropdown>
            </div>
          </div>
          <div class="badge bg-primary m-1 mt-2 mb-0" v-for="f in filters" :key="f.key">
            <div class="d-flex align-items-center justify-content-between">
                <span class="pe-2">{{ f.badge }}</span>
                <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && personalizations.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && personalizations.length == 0 && query ==''" @onButtonClicked="()=>showNewPersonalizarionModal = true" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && personalizations.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{ $t('personalizations.name')}}</th>
                      <th scope="col">{{ $t('personalizations.key')}}</th>
                      <th scope="col">{{ $t('personalizations.page_type')}}</th>
											<th scope="col">{{ $t('personalizations.status')}}</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in personalizations" :key="data.id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditPersonalizationClicked(data)">
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                        <p class="text-muted mb-0 small interact" v-on:click="onEditPersonalizationClicked(data)">{{getTemplateType(data.type)}}</p>
                      </td>
                      <td>
                        {{data.key}}
                      </td>
											<td>
												{{ $t(`personalizations.page_type_${data.pageType}`) }}
											</td>
											<td>
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 1,
                          'bg-warning': `${data.status}` == 0}">
                            {{ data.status == 1 ? $t('personalizations.status_active') : $t('personalizations.status_inactive') }}
                        </span>
											</td>
                      <td class="text-end">
                        <i class="fas text-primary me-2 fas fa-chart-line interact" v-on:click="onAnalyticsClicked(data)" v-if="data.type!='custom_code' && data.goal?._id!='none'"></i>
                        <i class="fas text-primary me-2 fas fa-eye interact" v-on:click="onPreviewPersonalization(data)"></i>
                        <i class="fas text-primary me-2 interact" :class="data.status == 1 ? 'fa-pause' : 'fa-upload'" v-on:click="onPublishOrUnpublishPersonalization(data)"></i>
                        <i class="fas text-primary me-2 fas fa-share-square interact" v-on:click="onCopyPersonalization(data)"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditPersonalizationClicked(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="!isLoading && rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onPersonalizationListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                      ></b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemovePersonalizationClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{ $t('personalizations.remove_personalization') }}</p>
        </b-modal>
        <b-modal
          title="Preview"
          v-model="showPreviewPanel"
          @ok.prevent="onConfirmPreViewClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <div class="row">
            <div class="col">
                <label>URL*</label>
                <input class="form-control" v-model="url"/>
            </div>
          </div>
        </b-modal>
        <b-modal
        v-model = "showNewPersonalizarionModal"
        title-class="font-18"
        hide-footer
        :title="$t('personalizations.new_personalization')">
        <div class="list-group list-group-flush">
          <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreatePersonalizationClicked('web')">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img
                    src="https://i.imgur.com/SXSdBrO.png"
                    alt=""
                    class="avatar-sm h-auto d-block rounded"
                  />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h4 class="font-size-13 text-truncate mb-1">
                    {{$t("personalizations.type_web")}}
                  </h4>
                  <p class="small mb-0">{{ $t('personalizations.type_web_subtitle') }}</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreatePersonalizationClicked('recommendations')">
                <div class="d-flex align-items-center">
                  <div class="me-3">
                    <img
                      src="https://i.imgur.com/7LVSbEP.png"
                      alt=""
                      class="avatar-sm h-auto d-block rounded"
                    />
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h4 class="font-size-13 text-truncate mb-1">
                      {{$t("personalizations.type_reco")}}
                    </h4>
                    <p class="small mb-0">{{ $t('personalizations.type_reco_subtitle') }}</p>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreatePersonalizationClicked('feedback')">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img
                    src="https://i.imgur.com/tsFVKw5.png"
                    alt=""
                    class="avatar-sm h-auto d-block rounded"
                  />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h4 class="font-size-13 text-truncate mb-1">
                    Feedback
                  </h4>
                  <p class="small mb-0">{{ $t('personalizations.type_feedback_subtitle') }}</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreatePersonalizationClicked('pop_up')">
                <div class="d-flex align-items-center">
                  <div class="me-3">
                    <img
                      src="https://i.imgur.com/r4zEUHz.png"
                      alt=""
                      class="avatar-sm h-auto d-block rounded"
                    />
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h4 class="font-size-13 text-truncate mb-1">
                      {{$t("personalizations.type_pop_up")}}
                    </h4>
                    <p class="small mb-0">{{ $t('personalizations.type_pop_up_subtitle') }}</p>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreatePersonalizationClicked('notifications')">
                <div class="d-flex align-items-center">
                  <div class="me-3">
                    <img
                      src="https://i.imgur.com/PBaRyqL.png"
                      alt=""
                      class="avatar-sm h-auto d-block rounded"
                    />
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h4 class="font-size-13 text-truncate mb-1">
                      {{$t("personalizations.type_notifications")}}
                    </h4>
                    <p class="small mb-0">{{ $t('personalizations.type_notifications_subtitle') }}</p>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreatePersonalizationClicked('custom_code')">
                <div class="d-flex align-items-center">
                  <div class="me-3">
                    <img
                      src="https://i.imgur.com/eYIn7lC.png"
                      alt=""
                      class="avatar-sm h-auto d-block rounded"
                    />
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h4 class="font-size-13 text-truncate mb-1">
                      {{$t("personalizations.type_custom_code")}}
                    </h4>
                    <p class="small mb-0">{{ $t('personalizations.type_custom_code_subtitle') }}</p>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreatePersonalizationClicked('API')">
                <div class="d-flex align-items-center">
                  <div class="me-3">
                    <img
                      src="https://gs-prod-public.goshops.ai/uploads/2024/09/1a603c82-f64b-4c9c-a007-74e7c4b2ff78.jpg"
                      alt=""
                      class="avatar-sm h-auto d-block rounded"
                    />
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h4 class="font-size-13 text-truncate mb-1">
                      {{ $t('personalizations.type_api_reco') }} 
                    </h4>
                    <p class="small mb-0">{{ $t('personalizations.type_api_reco_subtitle') }}</p>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreatePersonalizationClicked('API_SIMPLE')">
                <div class="d-flex align-items-center">
                  <div class="me-3">
                    <img
                      src="https://i.imgur.com/g46UIih.png"
                      alt=""
                      class="avatar-sm h-auto d-block rounded"
                    />
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h4 class="font-size-13 text-truncate mb-1">
                      API
                    </h4>
                    <p class="small mb-0">{{ $t('personalizations.type_api_subtitle') }}</p>
                  </div>
                </div>
              </a>
            </div>
        </b-modal>
        <b-modal
          :title="$t('personalizations.copy_to_project_title')"
          v-model="showCopyToProjectModal"
          @ok.prevent="onConfirmCopyPersonalization"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <div class="row">
            <div class="col">
                <label>{{$t('personalizations.project')}}*</label>
                <multiselect
                  :options="projects" 
                  v-model="auxProject"
                  track-by="_id" 
                  label="name"
                  selectLabel=""
                  deselectLabel=""
                  :placeholder= "$t('projects.select_placeholder')"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="form control"
                />
            </div>
          </div>
          <div class="row">
            <div class="col mt-4 bg-info bg-gradient text-white p-3 ms-3 me-3">
              <i class="mdi mdi-alert-circle-outline"></i> <p class="small">{{$t('personalizations.copy_to_project_info')}}</p>
            </div>
          </div>
        </b-modal>
        
        <Drawer
          @close="rightDrawer"
          :align="'right'"
          :closeable="true"
          :maskClosable="true"
          :zIndex="1002"
          class="h-100">
          <div v-if="openRightDrawer" class="h-100">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                    <i class="bx bx-gear font-size-16 align-middle me-1"></i>
                    {{$t('filter.title')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body flex-grow-1 h-100">
                <div>
                  <FilterP ref="filterRef"></FilterP>
                </div>
                <div class="position-fixed bottom-0 end-0 p-3">
                  <button class="btn btn-secondary me-2" @click="removeAllFilter">{{$t('filter.clean')}}</button>
                  <button class="btn btn-primary me-2" @click="applyCustomFilter">{{$t('filter.apply')}}</button>
                </div>
              </div>
            </div>
        </Drawer>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.interact {
  cursor: pointer;
}


</style>

<style>
#templates-modal .modal-body {
  background-color: var(--bs-body-bg);
}

.dropdown-toggle {
  padding-top: 7px !important;
}
</style>
